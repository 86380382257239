import { Objects } from '@goatlab/js-utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect, useMemo } from 'react'
import { useForm, useWatch, ValidationMode } from 'react-hook-form'
import { z, TypeOf } from 'zod'
import { TypedPathWrapper, typedPath } from './utils/typedPath'
import { castNameToString } from './utils/castNameToString'

let zodCounter = 0
/**
 *
 * @param schema
 * @param defaultValues Default values should not be undefined!
 * @param values
 * @returns
 */
export const useZodFormHook = <T extends z.ZodTypeAny>({
  schema,
  defaultValues,
  values,
  mode,
  async,
}: {
  schema: T
  // Don't leave the default values undefined!
  defaultValues: TypeOf<T>
  values?: TypeOf<T>
  mode?: keyof ValidationMode
  async?: boolean
}) => {
  if (!values) {
    values = defaultValues
  }

  const resolver = async
    ? zodResolver(schema, { async: true }, { mode: 'async' })
    : zodResolver(schema)

  // https://github.com/react-hook-form/resolvers/blob/master/zod/src/zod.ts
  const form = useForm<TypeOf<T>>({
    mode: mode || 'onChange',
    reValidateMode: 'onChange',
    resolver,
    defaultValues: Objects.deleteNulls(defaultValues || {}) as any,
    shouldUnregister: false,
    shouldFocusError: true,
    values: Objects.deleteNulls(values || {}) as any,
  })

  const path = typedPath<z.infer<typeof schema>>()

  const omitPathValues = path as Omit<
    typeof path,
    '$path' | '$rawPath' | '$raw' | 'Symbolvar'
  >

  return {
    ...form,
    zodSchema: schema,
    useWatch: (attributeName: string | TypedPathWrapper<any, any>) => {
      const castedName = castNameToString(attributeName)
      return useWatch({
        control: form.control,
        name: castedName as any,
      })
    },
    path: omitPathValues,
  }
}
