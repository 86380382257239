import React, { ReactNode, JSX } from 'react'
import { FieldErrors } from 'react-hook-form'
import { z, TypeOf } from 'zod'
import { UseZodFormHookReturnType } from '../types'

interface FormProps<T extends z.ZodTypeAny> {
  onSuccess?: (data: TypeOf<T>) => void
  onError?: (data: FieldErrors<TypeOf<T>> | undefined) => void
  notifyError?: (data: { title: string; description?: string }) => void
  beforeSubmit?: (data: TypeOf<T>) => TypeOf<T>
  formHook: UseZodFormHookReturnType<T>
  id?: string
  children: ReactNode
}

const findMessage = (
  arr: any[],
  path = ''
): { message: string; path: string } | null => {
  for (const [i, item] of arr.entries()) {
    const currentPath = `${path}[${i}]`
    if (item.message) {
      return {
        message: item.message,
        path: `${currentPath}.message`,
      }
    }
    for (const key in item) {
      if (typeof item[key] === 'object' || Array.isArray(item[key])) {
        const newPath = Array.isArray(item[key])
          ? `${currentPath}.${key}`
          : `${currentPath}.${key}`
        const foundMessage = findMessage(
          Array.isArray(item[key]) ? item[key] : [item[key]],
          newPath
        )
        if (foundMessage) {
          return foundMessage
        }
      }
    }
  }
  return null
}

export function Form<T extends z.ZodTypeAny>({
  formHook,
  onSuccess,
  onError,
  children,
  id,
  notifyError,
}: FormProps<T>): JSX.Element {
  const mockSuccess = () => {}
  const successCall = onSuccess ?? mockSuccess

  return (
    <form
      id={id}
      onSubmit={formHook.handleSubmit(successCall, (e) => {
        onError?.(e)
        const errorKeys = Object.keys(e)

        if (errorKeys.length > 0) {
          const keyName = errorKeys[0]

          if (e[keyName] && Array.isArray(e[keyName])) {
            const errorArray = (e[keyName] as any[])?.filter(Boolean)

            if (errorArray[0]) {
              const errorMessage = findMessage(errorArray)
              notifyError?.({
                title: `${keyName} [${errorMessage?.path}]`,
                description: errorMessage?.message,
              })
            }

            return
          }

          const errorMessage = String(e[keyName]?.message)
          notifyError?.({
            title: keyName,
            description: errorMessage,
          })
        }
      })}
    >
      {children}
    </form>
  )
}
