import { Unpacked } from '@goatlab/js-utils'
import { useLocalization } from '@goatlab/marketplace-i18n'
import {
  UseZodFormHookReturnType,
  useParseInputProps,
} from '@goatlab/react-zod-form'
import { TypedPathWrapper } from '@goatlab/react-zod-form'
import { ComponentType, FC, useEffect } from 'react'
import { OptionProps } from 'react-select'
import { Select } from './forms/fields/Select'
import { siteSettings } from '@src/api/settings/site-settings'

type SelectedLanguage = Unpacked<typeof siteSettings.siteHeader.languageMenu>

const CustomOption: ComponentType<OptionProps<SelectedLanguage, false>> = ({
  data,
  ...props
}) => (
  <div {...props.innerProps} className="flex items-center w-40 overflow-hidden">
    <div className="ml-4 w-5 h-4 flex-shrink-0">{data.icon}</div>
    <span className="m-2 w-32  flex-grow overflow-hidden">{data.label}</span>
  </div>
)

export interface LanguageSwitcherProps {
  name: string | TypedPathWrapper<any, any>
  label?: string | null
  defaultValue?: string | null
  onChange?: (value: string) => void
  formHook?: UseZodFormHookReturnType<any>
  tabIndex?: number
  inModal?: boolean
}

export const LanguageSwitcher: FC<LanguageSwitcherProps> = ({
  name: nameProp,
  defaultValue,
  onChange,
  formHook: hook,
  label,
  tabIndex,
  inModal,
}) => {
  const { siteHeader } = siteSettings
  const options: SelectedLanguage[] = siteHeader.languageMenu
  const localization = useLocalization()

  const { name, formHook } = useParseInputProps({
    name: nameProp,
    formHook: hook,
  })

  const handleItemClick = async (value: string) => {
    onChange?.(value)
    await localization.changeLanguage(value)
  }

  useEffect(() => {}, [defaultValue])

  return (
    <Select
      className="w-40"
      name={name}
      label={label}
      options={options}
      defaultValue={defaultValue}
      isClearable={false}
      tabIndex={tabIndex}
      onChange={handleItemClick}
      formHook={formHook}
      // @ts-ignore
      components={{ Option: CustomOption }}
      searchable={true}
      inModal={inModal}
    />
  )
}
