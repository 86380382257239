import { Objects, Primitive } from '@goatlab/js-utils'
import { TypedPathWrapper } from './typedPath'
import { UseZodFormHookReturnType } from '../types'
import { castNameToString } from './castNameToString'
import { useZodFormHook } from '../useZodFormHook'
import { z } from 'zod'
import { useMemo } from 'react'

export const useParseInputProps = ({
  name: nameProp,
  formHook: formHookProp,
}: {
  name: Primitive | TypedPathWrapper<any, any>
  formHook: UseZodFormHookReturnType<any> | undefined
}) => {
  const name = useMemo(() => castNameToString(nameProp), [nameProp])
  const formHook = useGetFormHook({ formHook: formHookProp })
  const errorMessage = useMemo(
    () => getErrorsFromState({ formHook, name }),
    [formHook, name]
  )

  return {
    name,
    formHook,
    errorMessage,
  }
}

export const useGetFormHook = ({
  formHook,
}: {
  formHook: UseZodFormHookReturnType<any> | undefined
}): UseZodFormHookReturnType<any> => {
  const defaultHook = useZodFormHook({
    schema: z.any(),
    defaultValues: '',
  })
  return formHook || defaultHook
}

export const getErrorsFromState = ({
  formHook,
  name,
}: {
  formHook: UseZodFormHookReturnType<any>
  name: string
}) => {
  return Objects.getFromPath(formHook?.formState?.errors || {}, name).value
    ?.message
}
