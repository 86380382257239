import { create } from 'zustand'
import { ZodTypeAny, z, ZodType } from 'zod'

type AnyObject = { [key: string]: any }

type StoreState<T> = {
  formValues: T
  setFormValues: (values: Partial<T>) => void
  reset: () => void
}

const useStore = create<StoreState<AnyObject>>((set) => ({
  formValues: {},
  setFormValues: (values) =>
    set((state) => ({ formValues: { ...state.formValues, ...values } })),
  reset: () => set({ formValues: {} }),
}))

export function useZustandForm<T extends ZodTypeAny = ZodTypeAny>(schema?: T) {
  const setFormValues = (
    values: Partial<z.infer<T extends ZodTypeAny ? T : ZodType<AnyObject, any>>>
  ) => {
    if (schema) {
      const result = (schema as any).deepPartial().safeParse(values)
      if (result.success) {
        useStore.getState().setFormValues(result.data)
      }
    } else {
      useStore.getState().setFormValues(values as any)
    }
  }

  const reset = () => {
    if (!schema) {
      useStore.getState().reset()
      return
    }

    const result = schema.safeParse({})
    if (result.success) {
      useStore.getState().setFormValues(result.data)
    } else {
      console.error('Validation failed', result.error)
    }
    return
  }

  return {
    formValues: useStore().formValues as z.infer<
      T extends ZodTypeAny ? T : ZodType<AnyObject, any>
    >,
    setFormValues,
    reset,
  }
}
