import { useAccountStore } from '@goatlab/marketplace-states'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useAuth } from '@components/auth/store/auth.store'
import { useSite } from '@contexts/site/site.context'
import { STATIC_ROUTES, DYNAMIC_ROUTES, RouteParam } from '@src/routes'

type StaticRoutes = typeof STATIC_ROUTES
type DynamicRoutes = {
  [K in keyof typeof DYNAMIC_ROUTES]: (
    params?: RouteParam,
  ) => ReturnType<(typeof DYNAMIC_ROUTES)[K]>
}

const staticRoutes = STATIC_ROUTES

export const useStateAwareRoutes = (): {
  static: StaticRoutes
  dynamic: DynamicRoutes
} => {
  const { selectedAccount } = useAccountStore()
  const { isSpace } = useSite()
  const { user } = useAuth()
  const router = useRouter()

  const accountParam = router.query.accountId as string
  const spaceQueryParam = router.query.space as string

  const accountId = user?.account?.id
    ? selectedAccount?.id || accountParam
    : undefined

  const dynamicRoutes: DynamicRoutes = useMemo(() => {
    const router: Partial<DynamicRoutes> = {}

    for (const key in DYNAMIC_ROUTES) {
      if (Object.prototype.hasOwnProperty.call(DYNAMIC_ROUTES, key)) {
        const routeValue = DYNAMIC_ROUTES[key as keyof typeof DYNAMIC_ROUTES]
        router[key as keyof DynamicRoutes] = (params?: RouteParam) =>
          routeValue({
            accountId,
            space: isSpace ? '' : spaceQueryParam,
            ...params,
          })
      }
    }

    return router as DynamicRoutes
  }, [accountId, spaceQueryParam, user?.firebaseId])

  return { static: staticRoutes, dynamic: dynamicRoutes }
}
