import {FC, useState, ReactNode} from 'react'

interface TooltipProps {
  className?: string
  tip: ReactNode
}

export const Tooltip: FC<TooltipProps> = ({tip, className}) => {
  const [showTip, setShowTip] = useState(false)

  const handleMouseOver = () => {
    setShowTip(true)
  }

  const handleMouseOut = () => {
    setShowTip(false)
  }

  const handleClick = () => {
    setShowTip(!showTip)
  }

  return (
    <div
      className={`relative inline-flex bg-slate-100 text-slate-500 items-center items-center justify-center w-6 h-6 rounded-full text-xs font-semibold cursor-pointer ${className}`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
    >
      ?
      {showTip && (
        <div
          className="absolute z-10 w-32 px-4 py-2 mt-2 text-sm text-white bg-black rounded-md shadow-lg -left-1/2 transform translate-x-1/2"
          style={{bottom: '100%'}}
        >
          {tip}
        </div>
      )}
    </div>
  )
}
