export type TypedPathKey = string | symbol | number

function appendStringPathChunk(path: string, chunk: TypedPathKey): string {
  if (typeof chunk === 'number') {
    return `${path}[${chunk}]`
  }

  return appendStringSymbolChunkToPath(path, chunk)
}

function appendStringSymbolChunkToPath(path: string, chunk: string | symbol) {
  return `${path}${path === '' ? '' : '.'}${chunk.toString()}`
}

function pathToString(path: TypedPathKey[]): string {
  // eslint-disable-next-line unicorn/no-array-reduce
  return path.reduce<string>((current, next) => {
    return appendStringPathChunk(current, next)
  }, '')
}

export type TypedPathFunction<ResultType> = (...args: any[]) => ResultType

export type TypedPathHandlersConfig = Record<
  string,
  <T extends TypedPathHandlersConfig>(
    path: TypedPathKey[],
    additionalHandlers?: T,
  ) => any
>

const defaultHandlersConfig = {
  $path: (path: TypedPathKey[]) => pathToString(path),
  /**
   * @deprecated This method transforms all path chunks to strings.
   * If you need the path with numbers and Symbols - use $rawPath
   */
  $raw: (path: TypedPathKey[]) => path.map(chunk => chunk.toString()),
  $rawPath: (path: TypedPathKey[]) => path,
  toString: (path: TypedPathKey[]) => () => pathToString(path),
  [Symbol.toStringTag]: (path: TypedPathKey[]) => pathToString(path),
  valueOf: (path: TypedPathKey[]) => () => pathToString(path),
}

export type DefaultHandlers = typeof defaultHandlersConfig

export type TypedPathHandlers<ConfigType extends TypedPathHandlersConfig> = {
  [key in keyof ConfigType]: ReturnType<ConfigType[key]>
}

export type TypedPathWrapper<
  OriginalType,
  HandlersType extends TypedPathHandlers<Record<never, never>>,
> = (OriginalType extends (infer OriginalArrayItemType)[]
  ? {
      // Intersect the array type with its item type
      [index: number]: TypedPathWrapper<OriginalArrayItemType, HandlersType>
    } & TypedPathWrapper<OriginalArrayItemType, HandlersType>
  : OriginalType extends TypedPathFunction<infer OriginalFunctionResultType>
  ? (() => TypedPathWrapper<OriginalFunctionResultType, HandlersType>) & {
      [P in keyof Required<OriginalFunctionResultType>]: TypedPathWrapper<
        OriginalFunctionResultType[P],
        HandlersType
      >
    }
  : {
      [P in keyof Required<OriginalType>]: TypedPathWrapper<
        OriginalType[P],
        HandlersType
      >
    }) &
  TypedPathHandlers<HandlersType>

export type UnwrapTypedPath<T> = T extends TypedPathWrapper<infer Original, any>
  ? Original
  : T extends TypedPathFunction<infer Result>
  ? UnwrapTypedPath<Result>
  : T extends any[]
  ? UnwrapTypedPathArray<T[number]>[]
  : T extends Record<string, any>
  ? UnwrapTypedPathObject<T>
  : T

type UnwrapTypedPathObject<T> = {
  [P in keyof T]: UnwrapTypedPath<T[P]>
}

type UnwrapTypedPathArray<T> = UnwrapTypedPath<T>

function convertNumericKeyToNumber(key: TypedPathKey): TypedPathKey {
  if (typeof key === 'string') {
    const keyAsNumber = Number(key)
    // eslint-disable-next-line no-self-compare
    if (keyAsNumber === keyAsNumber) {
      return keyAsNumber
    }
  }

  return key
}

function getHandlerByNameKey<K extends TypedPathHandlersConfig>(
  name: TypedPathKey,
  additionalHandlers?: K,
) {
  // eslint-disable-next-line no-prototype-builtins
  if (additionalHandlers?.hasOwnProperty(name)) {
    return additionalHandlers[name as string]
  }

  if (defaultHandlersConfig[name as keyof typeof defaultHandlersConfig]) {
    return defaultHandlersConfig[name as keyof typeof defaultHandlersConfig]
  }
}

const emptyObject = {}
export function typedPath<
  OriginalObjectType,
  HandlersType extends TypedPathHandlersConfig = Record<never, never>,
>(
  additionalHandlers?: HandlersType,
  path: TypedPathKey[] = [],
): TypedPathWrapper<OriginalObjectType, HandlersType & DefaultHandlers> {
  return new Proxy(emptyObject, {
    get(target: unknown, name: TypedPathKey) {
      const handler = getHandlerByNameKey(name, additionalHandlers)

      if (handler) {
        return handler(path, additionalHandlers)
      }

      // If the current path points to an array, and the name is a property of the array items,
      // then we unwrap the array and directly access the property.
      // For simplicity, we're assuming here that all items of the array have the same type.
      if (Array.isArray(path.at(-1)) && typeof name !== 'number') {
        return typedPath(additionalHandlers, [
          ...path,
          0, // Access the first item of the array
          name,
        ])
      }

      return typedPath(additionalHandlers, [
        ...path,
        convertNumericKeyToNumber(name),
      ])
    },
  }) as TypedPathWrapper<OriginalObjectType, HandlersType & DefaultHandlers>
}
